import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Http from "../helpers/Http";
import Template from "./Template";
import newsIcon from "../images/news.png";

const NewsContent = () => {
  const [news, setNews] = useState([]);
  useEffect(() => {
    try {
      Http.get("content/type/3")
        .then((response) => {
          console.log("main news: ", response.data.contents);
          setNews(response.data.contents);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <Container>
      <Template data={news} image={newsIcon} type="NEWS" />
    </Container>
  );
};

export default NewsContent;

const Container = styled.div`
  background-color: #fff;
  padding: 32px 4%;
`;
